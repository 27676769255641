<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-18 16:09:01
 * @LastEditTime: 2020-04-30 17:55:48
 -->
<template>
  <div v-loading="loading">
    <el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
      <el-form-item label="面试时间">
        <el-date-picker
          v-model="searchForm.interviewTime"
          type="date"
          size="small"
          value-format="yyyy-MM-dd"
          clearable
          placeholder="请选择面试时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="来源渠道">
        <el-select v-model="searchForm.sources" size="small" placeholder="请选择来源渠道" clearable>
          <el-option
            v-for="(sources, i) in sourcesArr"
            :key="i"
            :label="sources.dictName"
            :value="sources.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应聘岗位">
        <el-select v-model="searchForm.jobId" size="small" placeholder="请选择应聘岗位" clearable>
          <el-option
            v-for="item in jobList"
            :key="item.id"
            :label="item.jobOrOfficeName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作年限">
        <el-select v-model="searchForm.yearsWorking" size="small" placeholder="请选择工作年限" clearable>
          <el-option
            v-for="(workyear,i) in yearsWorkingList"
            :key="i"
            :label="workyear.dictName"
            :value="workyear.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最高学历">
        <el-select
          v-model="searchForm.highestEducation"
          size="small"
          placeholder="请选择最高学历"
          clearable
        >
          <el-option
            v-for="(item, index) in highestEducationList"
            :key="index"
            :label="item.dictName"
            :value="item.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="searchForm.createTimeBefore"
          size="small"
          type="date"
          value-format="yyyy-MM-dd [00:00:00]"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
        <span style="margin:0 5px">-</span>
        <el-date-picker
          v-model="searchForm.createTimeEnd"
          size="small"
          type="date"
          value-format="yyyy-MM-dd [23:59:59]"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-button type="primary" size="small" @click="_getList(1)" style="margin-left:5px" icon="el-icon-search">查询</el-button>
    </el-form>
    <el-table :data="list" stripe border>
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column
        v-for="(col,i) in columns"
        :prop="col.prop"
        :key="i"
        :align="col.prop == 'basic' ? 'left' :'center'"
        :label="col.label"
        :width="col.prop == 'basic' ? 300 :200"
        show-overflow-tooltip
      >
        <template
          v-slot="{row}"
          v-if="col.prop == 'interviewTime' || col.prop == 'createTime'"
        >{{row[col.prop].slice(0,10)}}</template>
        <template
          v-slot="{ row }"
          v-else-if="col.prop == 'graduatedSchool'"
        >{{ row.graduatedSchool }}/{{ row.industry }}</template>
        <template v-slot="{ row }" v-else-if="col.prop == 'basic'">
          <p
            class="moryeBasic"
          >{{row.name}} <i :style="{color : row.sex=='1' ? '#498DF0' : '#FF7371'}">{{row.sex=='1'?'♂':'♀'}}</i> {{row.yearsWorking}}·{{row.highestEducationName}}</p>
          <p class="moryeBasic">{{row.phoneNumber}}/{{row.mailbox}}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="170">
        <template v-slot="{row}">
          <el-button type="text" v-for="(item, i) in commands" :key="i" @click="handleRowClick(row,item)">{{item.label}}</el-button>
          <!--<el-dropdown>
            <el-button type="text"><img src="../../../../assets/button/操作图标@3x.png" width="16px" /></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, i) in commands"
                @click.native.stop="handleRowClick(row,item)"
                :command="item"
                :key="i"
              >{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>!-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
      :current-page="searchForm.pageNo"
      :page-sizes="[20, 40, 80, 100]"
      :page-size="searchForm.pageSize"
      style="margin:20px 0;text-align: right;"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchForm.totalNum"
    ></el-pagination>
  </div>
</template>
<script>
import { ingInterview } from '@/api/recruitment_allocation';
export default {
  props: ['jobArr'],
  data() {
    return {
      searchForm: {
        interviewTime: '',
        keyword: '',
        sources: '',
        createTimeEnd: '',
        createTimeBefore: '',
        highestEducation: '',
        yearsWorking: '',
        jobId: '',
        pageNo: 1,
        pageSize: 20,
        totalNum: 0
      },
      columns: [
        { label: '面试时间', prop: 'interviewTime' },
        { label: '基本信息', prop: 'basic' },
        { label: '毕业院校/专业', prop: 'graduatedSchool' },
        { label: '应聘岗位', prop: 'jobName' },
        { label: '来源渠道', prop: 'sourcesName' },
        { label: '创建时间', prop: 'createTime' },
        { label: '面试轮次', prop: 'interviewName' }
      ],
      commands: [
        { label: '面试详情', value: '1' },
        { label: '更换面试官', value: '2' }
      ],
      jobList: this.jobArr,
      loading: false,
      admissiondig: false,
      dateRange: '',
      list: [],
      multipleSelection: [],
      sourcesArr: [],
      yearsWorkingList: [],
      highestEducationList: [],
      hireFormList: [],
      probationArr: [],
      admissionform: {
        entryTime: '',
        hireForm: '',
        testDate: '',
        turnPositiveTime: ''
      }
    };
  },
  mounted() {
    this.sourcesArr = this.$store.state.app.dict.filter((item) => item.groupId == 'fromType');
    this.yearsWorkingList = this.$store.state.app.dict.filter((item) => item.groupId == 'experience');
    this.highestEducationList = this.$store.state.app.dict.filter((item) => item.groupId == 'highestEducation');
    this.hireFormList = this.$store.state.app.dict.filter((item) => item.groupId == 'hireForm');
    this.probationArr = this.$store.state.app.dict.filter((item) => item.groupId == 'probationPeriod');
    this._getList();
  },
  methods: {
    _handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this._getList();
    },
    _handleCurrentChange(val) {
      this.searchForm.pageNo = val;
      this._getList();
    },
    _getList(page) {
      page ? this.searchForm.pageNo = page : '';
      this.loading = true;
      ingInterview(this.searchForm).then((res) => {
        this.loading = false;
        this.multipleSelection = [];
        this.list = res.list;
        this.searchForm.totalNum = res.totalNum;
      });
    },
    _dateRangeChange(val) {
      if (val) {
        this.searchForm.createTimeBefore = val[0];
        this.searchForm.createTimeEnd = val[1];
      } else {
        this._clear();
      }
    },
    _clear() {
      this.dateRange = '';
      this.searchForm.createTimeBefore = '';
      this.searchForm.createTimeEnd = '';
    },
    handleRowClick(row, item) {
      switch (item.value) {
        case '1':
          this.$router.push({ name: 'InterviewInfo', query: {resumeId: row.recruitId, name: row.name}});
          break;
        default:
          this.$emit('showDialog', row);
          break;
      }
    }
  },
  watch: {
    jobArr: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.jobList = newVal;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.admission {
  .buttonDiv {
    text-align: center;
    margin-top: 20px;
  }
  .losetitle {
    margin: 0 20px;
    padding-bottom: 20px;
    // border-bottom: 1px dashed #ccc;
    strong {
      font-size: 18px;
    }
    div {
      margin-top: 10px;
      line-height: 20px;
    }
  }
  .border-line {
    width: 93%;
    margin: 0 20px 20px;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #ccc 0%,
      #ccc 50%,
      transparent 50%
    );
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }
  .loseType,
  .reason {
    margin-left: 20px;
    .el-radio-group {
      margin: 20px 0;
    }
  }
  .reason {
    .el-radio {
      min-width: 16%;
      height: 25px;
    }
  }
}
</style>
