<template>
	<div class="app-container interViewList">
		<el-tabs v-model="activeName">
			<!--<el-tab-pane label="面试登记表" name="0" lazy>
        <registrationForm :jobArr="jobList" ref="child0"></registrationForm>
      </el-tab-pane>!-->
			<el-tab-pane :label="`当日待面试(${_returnNumber(0)})`" name="1" lazy>
				<TodayPage :jobArr="jobList" ref="child1" @showDialog="_getInterView"></TodayPage>
			</el-tab-pane>
			<el-tab-pane :label="`待面试(${_returnNumber(1)})`" name="2" lazy>
				<WaitPage :jobArr="jobList" ref="child2" @showDialog="_getInterView"></WaitPage>
			</el-tab-pane>
			<el-tab-pane :label="`面试中(${_returnNumber(2)})`" name="3" lazy>
				<IngPage :jobArr="jobList" ref="child3" @showDialog="_getInterView"></IngPage>
			</el-tab-pane>
			<el-tab-pane :label="`已面试(${_returnNumber(3)})`" name="4" lazy>
				<PassPage :jobArr="jobList" ref="child4" @showDialog="_getDetail" @updateList="_updateList"></PassPage>
			</el-tab-pane>
		</el-tabs>
		<el-dialog title="更改面试信息" center :visible.sync="changeView" width="40%">
			<el-alert
				title="温馨提示：更新面试官后会把当前轮次面试替换掉，且不可恢复，请谨慎操作!"
				type="warning"
				show-icon
				:closable="false"
			></el-alert>
			<el-form :model="interviewForm" ref="interviewForm" label-width="120px" style="margin-top: 20px">
				<el-form-item label="面试轮次" required>
					<el-input v-model="interviewForm.interviewName" disabled></el-input>
				</el-form-item>
				<el-form-item label="变更前面试官" required>
					<el-input v-model="interviewForm.staffName" disabled></el-input>
				</el-form-item>
				<el-form-item label="变更后面试官" required>
					<el-select
						v-model="interviewForm.staffId"
						placeholder="请选择变更后面试官"
						style="width: 100%"
						filterable
						:loading="selectloading"
					>
						<el-option v-for="item in dutyUsers" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="changeView = false">取 消</el-button>
				<el-button type="primary" @click="confirmChangeStaff">确 定</el-button>
			</span>
		</el-dialog>
		<MoveDialog
			v-if="dialogStatus"
			:currentRow="detail"
			:visible.sync="dialogStatus"
			:title="'移动简历'"
			:classifyListNew="classifyList"
			@updateList="_updateList"
		></MoveDialog>
	</div>
</template>
<script>
import { changeStaff, interviewNum, classifyList, selectPrincipallist } from '@/api/recruitment_allocation';
import { getPositions } from '@/api/common.js';
import MoveDialog from '../brainbank/components/moveDialog';
import TodayPage from './page/today';
import WaitPage from './page/wait';
import PassPage from './page/pass';
import IngPage from './page/ing';
// import registrationForm from "./page/registrationForm"
export default {
	name: 'interview',
	components: { MoveDialog, TodayPage, WaitPage, PassPage, IngPage },
	data() {
		return {
			selectloading: false,
			dialogStatus: false,
			changeView: false,
			admissiondig: false,
			jobList: [],
			activeName: '1',
			numList: [],
			detail: {},
			dutyUsers: [],
			typeArr: [],
			probationArr: [],
			classifyList: [],
			interviewData: [],
			interviewForm: {
				id: '',
				processId: '',
				staffId: ''
			},
			admissionform: {
				entryTime: '',
				hireForm: '',
				testDate: '',
				turnPositiveTime: ''
			}
		};
	},
	mounted() {
		const params = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (params && params.activeName) {
			this.activeName = params.activeName;
		}
		// if(params&&params.searchForm)this.searchForm=params.searchForm
		this._getClassifyList();
		this._getListNum();
		this._getJobList();
		// this.search();
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				searchForm: this.searchForm,
				activeName: this.activeName
			})
		);
		next();
	},
	methods: {
		_getClassifyList() {
			classifyList({}).then((res) => {
				this.classifyList = res;
			});
		},
		_getListNum() {
			interviewNum({}).then((res) => {
				delete res._responseStatusCode;
				this.numList = res;
			});
		},
		_getJobList() {
			getPositions({ type: 2, pageNo: 1, pageSize: 20000 }).then((res) => {
				this.jobList = res.list;
			});
		},
		_getDetail(obj) {
			this.detail = obj;
			this.dialogStatus = true;
		},
		_getInterView(obj) {
			this.interviewForm = obj;
			this.dutyUsers = [];
			selectPrincipallist({}).then((res) => {
				if (Array.isArray(res)) {
					res.map((it) => {
						this.dutyUsers.push({
							label: `${it.staffName} ${it.officeName ? `(${it.officeName})` : ''}`,
							value: it.staffId
						});
					});
				}
			});
			this.changeView = true;
		},
		confirmChangeStaff() {
			const params = {
				processId: this.interviewForm.processId,
				staffId: this.interviewForm.staffId
			};

			changeStaff(params).then((res) => {
				if (res.code == 0) {
					this._updateList();
					this.changeView = false;
					this.$message.success('更换面试官成功');
				}
			});
		},
		showItem(item, row) {
			if (this.activeName == '3' && item.value == 2) {
				return false;
			}
			if (this.activeName != 3 && (item.value == 3 || item.value == 4)) {
				return false;
			}
			if (this.activeName == '3' && row.approvalStatus == '0' && item.value == '3') {
				return false;
			}
			return true;
		},
		_updateList() {
			this._getListNum();
			this.$refs[`child${this.activeName}`] && this.$refs[`child${this.activeName}`]._getList();
		},
		_returnNumber(status) {
			if (this.numList && this.numList.length >= 1) {
				for (const key in this.numList) {
					if (this.numList[key].status == status) {
						return this.numList[key].count;
					}
				}
				return 0;
			}
			return 0;
		}
	},
	watch: {
		activeName(newVal) {
			this._getListNum();
			this.$refs[`child${newVal}`] && this.$refs[`child${newVal}`]._getList();
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
</style>
<style lang="scss">
.interViewList {
	label {
		font-weight: normal;
	}
	.router-link {
		margin-right: 10px;
	}
	.searchForm .el-form-item {
		.el-input,
		.el-select {
			width: 200px;
		}
		.datespan {
			margin: 0 10px;
		}
		// margin-right: 0;
	}
	.admission {
		.buttonDiv {
			text-align: center;
			margin-top: 20px;
		}
		.losetitle {
			margin: 0 20px;
			padding-bottom: 20px;
			// border-bottom: 1px dashed #ccc;
			strong {
				font-size: 18px;
			}
			div {
				margin-top: 10px;
				line-height: 20px;
			}
		}
		.border-line {
			width: 93%;
			margin: 0 20px 20px;
			height: 1px;
			background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
			background-size: 10px 1px;
			background-repeat: repeat-x;
		}
		.loseType,
		.reason {
			margin-left: 20px;
			.el-radio-group {
				margin: 20px 0;
			}
		}
		.reason {
			.el-radio {
				min-width: 16%;
				height: 25px;
			}
		}
	}
	.el-dialog__footer {
		text-align: center;
	}
}
</style>
