<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-16 10:53:02
 * @LastEditTime: 2020-04-30 17:52:19
 -->
<template>
  <div v-loading="loading">
    <el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
      <el-form-item label="面试时间">
        <el-date-picker
          v-model="searchForm.interviewTime"
          type="date"
          size="small"
          value-format="yyyy-MM-dd"
          clearable
          placeholder="请选择面试时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="来源渠道">
        <el-select v-model="searchForm.sources" size="small" placeholder="请选择来源渠道" clearable>
          <el-option
            v-for="(sources, i) in sourcesArr"
            :key="i"
            :label="sources.dictName"
            :value="sources.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应聘岗位">
        <el-select v-model="searchForm.jobId" size="small" placeholder="请选择应聘岗位" clearable>
          <el-option
            v-for="item in jobList"
            :key="item.id"
            :label="item.jobOrOfficeName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作年限">
        <el-select v-model="searchForm.yearsWorking" size="small" placeholder="请选择工作年限" clearable>
          <el-option
            v-for="(workyear,i) in yearsWorkingList"
            :key="i"
            :label="workyear.dictName"
            :value="workyear.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最高学历">
        <el-select
          v-model="searchForm.highestEducation"
          size="small"
          placeholder="请选择最高学历"
          clearable
        >
          <el-option
            v-for="(item, index) in highestEducationList"
            :key="index"
            :label="item.dictName"
            :value="item.dictId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="面试结果">
        <el-select v-model="searchForm.processStatus" size="small" placeholder="请选择结果类型" clearable>
          <el-option value="3" label="不通过"></el-option>
          <el-option value="2" label="通过"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="searchForm.createTimeBefore"
          size="small"
          type="date"
          value-format="yyyy-MM-dd [00:00:00]"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
        <span style="margin:0 5px">-</span>
        <el-date-picker
          v-model="searchForm.createTimeEnd"
          size="small"
          type="date"
          value-format="yyyy-MM-dd [23:59:59]"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-button type="primary" size="small" @click="_getList(1)" style="margin-left:5px" icon="el-icon-search">查询</el-button>
      <el-button type="primary" size="small" class="fr" @click="_exportPage()" icon="el-icon-download">批量导出</el-button>
    </el-form>
    <el-table :data="list" stripe border @selection-change="_handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column
        v-for="(col,i) in columns"
        :prop="col.prop"
        :key="i"
        :align="col.prop == 'basic' ? 'left' :'center'"
        :label="col.label"
        :width="col.prop == 'basic' ? 300 :200"
        show-overflow-tooltip
      >
        <template
          v-slot="{row}"
          v-if="col.prop == 'interviewTime' || col.prop == 'createTime'"
        >{{row[col.prop].slice(0,10)}}</template>
        <template
          v-slot="{ row }"
          v-else-if="col.prop == 'graduatedSchool'"
        >{{ row.graduatedSchool }}/{{ row.industry }}</template>
        <template v-slot="{ row }" v-else-if="col.prop == 'basic'">
          <p
            class="moryeBasic"
          >{{row.name}} <i :style="{color : row.sex=='1' ? '#498DF0' : '#FF7371'}">{{row.sex=='1'?'♂':'♀'}}</i> {{row.yearsWorking}}·{{row.highestEducationName}}</p>
          <p class="moryeBasic">{{row.phoneNumber}}/{{row.mailbox}}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="面试结果" width="200">
        <template slot-scope="{row}">
          <el-tag :type="row.processStatus=='2'?'success':'danger'" >{{row.processStatus=='2'?'通过':'不通过'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="入职审批结果" width="200">
        <template slot-scope="{row}">
          <el-tag :type="row.approvalStatus=='1'?'success':row.approvalStatus=='2'?'danger':'warning'" v-if="row.approvalStatus!='5'">{{row.approvalStatusName}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="170">
        <template v-slot="{row}">
         <!--<el-button type="text" v-for="(item, i) in commands" :key="i" v-show="showItem(item,row)" @click="handleRowClick(row,item)">{{item.label}}</el-button>!-->
          <el-dropdown>
            <el-button type="text"><img src="../../../../assets/button/操作图标@3x.png" width="16px" /></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, i) in commands"
                @click.native.stop="handleRowClick(row,item)"
                :command="item"
                v-show="showItem(item,row)"
                :key="i"
              >{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- :style="item.value == 2 && row.processStatus ==2 && row.approvalStatus != 1 ? 'background-color: #ddd;' : ''" -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
      :current-page="searchForm.pageNo"
      :page-sizes="[20, 40, 80, 100]"
      :page-size="searchForm.pageSize"
      style="margin:20px 0;text-align: right;"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchForm.totalNum"
    ></el-pagination>
    <el-dialog title="录取信息" :visible.sync="admissiondig" class="admission" width="40%" center>
      <div class="losetitle">
        <strong>{{admissionform.name}}</strong>
        <div>{{`${admissionform.jobName} ${admissionform.phoneNumber} ${admissionform.mailbox}`}}</div>
      </div>
      <p class="border-line"></p>
      <el-form
        :model="admissionform"
        ref="admissionform"
        label-width="140px"
        style="margin-top:20px"
      >
        <el-form-item label="入职时间">
          <el-date-picker
            v-model="admissionform.entryTime"
            type="date"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间"
            style="width:80%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="聘用形式" :rules="{required:true,message:'请选择聘用形式'}" prop="hireForm">
          <el-select v-model="admissionform.hireForm" placeholder="请选择聘用形式" style="width:80%">
            <el-option
              v-for="(item, i) in hireFormList"
              :key="i"
              :value="item.dictId"
              :label="item.dictName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试用期" :rules="{required:true,message:'请选择试用期长度'}" prop="testDate">
          <el-select v-model="admissionform.testDate" placeholder="请选择试用期长度" style="width:80%">
            <el-option
              v-for="(item,i) in probationArr"
              :key="i"
              :label="item.dictName"
              :value="item.dictId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转正日期">
          <el-date-picker
            v-model="admissionform.turnPositiveTime"
            type="date"
            :picker-options="pickerOption1s"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间"
            style="width:80%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click=" admissiondig= false">取 消</el-button>
        <el-button type="primary" @click="_admissionConfirm" :loading="admissionStatus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { passInterview, employment } from '@/api/recruitment_allocation';
export default {
  props: ['jobArr'],
  data() {
    return {
      searchForm: {
        interviewTime: '',
        keyword: '',
        sources: '',
        createTimeEnd: '',
        createTimeBefore: '',
        highestEducation: '',
        yearsWorking: '',
        jobId: '',
        processStatus: '',
        pageNo: 1,
        pageSize: 20,
        totalNum: 0
      },
      columns: [
        { label: '面试时间', prop: 'interviewTime' },
        { label: '基本信息', prop: 'basic' },
        { label: '毕业院校/专业', prop: 'graduatedSchool' },
        { label: '应聘岗位', prop: 'jobName' },
        { label: '来源渠道', prop: 'sourcesName' },
        { label: '创建时间', prop: 'createTime' },
        { label: '面试轮次', prop: 'interviewName' }
      ],
      commands: [
        { label: '移动', value: '1' },
        { label: '录取', value: '2' },
        { label: '面试详情', value: '3' },
        { label: '入职审批详情', value: '4' },
        { label: '导出', value: '5' }
      ],
      pickerOptions: {
        disabledDate: (time) => time.getTime() < new Date(new Date().setMonth(new Date().getMonth() == 0 ? 12 : new Date().getMonth() - 1)).setDate(1) - (86400 * 1000)
      },

      pickerOption1s: {
        disabledDate: (time) => {
          if (this.admissionform.entryTime) {
            return time.getTime() < new Date(this.admissionform.entryTime);
          }
            return time.getTime() + 86400000 < new Date();

        }
      },
      jobList: this.jobArr,
      loading: false,
      admissiondig: false,
      admissionStatus: false,
      dateRange: '',
      list: [],
      multipleSelection: [],
      sourcesArr: [],
      yearsWorkingList: [],
      highestEducationList: [],
      hireFormList: [],
      probationArr: [],
      admissionform: {
        entryTime: '',
        hireForm: '',
        testDate: '',
        turnPositiveTime: ''
      }
    };
  },
  mounted() {
    this.sourcesArr = this.$store.state.app.dict.filter((item) => item.groupId == 'fromType');
    this.yearsWorkingList = this.$store.state.app.dict.filter((item) => item.groupId == 'experience');
    this.highestEducationList = this.$store.state.app.dict.filter((item) => item.groupId == 'highestEducation');
    this.hireFormList = this.$store.state.app.dict.filter((item) => item.groupId == 'hireForm');
    this.probationArr = this.$store.state.app.dict.filter((item) => item.groupId == 'probationPeriod');
    this._getList();
  },
  methods: {
    _handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this._getList();
    },
    _handleCurrentChange(val) {
      this.searchForm.pageNo = val;
      this._getList();
    },
    _getList(page) {
      page ? (this.searchForm.pageNo = page) : '';
      this.loading = true;
      passInterview(this.searchForm).then((res) => {
        this.loading = false;
        this.multipleSelection = [];
        this.list = res.list;
        this.searchForm.totalNum = res.totalNum;
      });
    },
    _dateRangeChange(val) {
      if (val) {
        this.searchForm.createTimeBefore = val[0];
        this.searchForm.createTimeEnd = val[1];
      } else {
        this._clear();
      }
    },
    _clear() {
      this.dateRange = '';
      this.searchForm.createTimeBefore = '';
      this.searchForm.createTimeEnd = '';
    },
    _handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    _exportPage(id) {
      let _url = '/v1/recruit/resume/list/export?params=';

      if (id) {
        const _obj = {
          ids: [id]
        };

        _url += encodeURIComponent(JSON.stringify(_obj));
      } else {
        if (this.multipleSelection.length <= 0) {
          this.$message.error('请先选择记录');
          return false;
        }
        const _list = [];

        this.multipleSelection.forEach((item) => {
          _list.push(item.recruitId);
        });
        const _obj = {
          ids: _list
        };

        _url += encodeURIComponent(JSON.stringify(_obj));
      }
      window.open(document.location.origin + _url);
    },
    handleRowClick(row, item) {
      switch (item.value) {
        case '1':
          this.$emit('showDialog', row);
          break;
        case '2':
          if (row.approvalStatus == 1 && row.processStatus == 2) {
            this.admissionform = row;
            this.admissiondig = true;
          } else {
            this.$message.warning(`${row.name}投递者录取需要审批通过后，才能进行录取操作！`);
          }
          break;
        case '3':
          this.$router.push({ name: 'InterviewInfo', query: {resumeId: row.recruitId, name: row.name}});
          break;
        case '5':
          this._exportPage(row.recruitId);
          break;
        default:
          this.$router.push({name: 'AdmissionApply', query: { id: row.recruitId, approvalStatus: row.approvalStatus, approvalStatusName: row.approvalStatusName, approvalMainProcessId: row.approvalMainProcessId, type: 1}});
          break;
      }
    },
    showItem(item, row) {
      switch (item.value) {
        case '1':
          if (row.processStatus != 2 || row.approvalStatus == 0 || row.approvalStatus == 2) {
            return true;
          }
            return false;

        case '2':
          if (
            row.processStatus == 2 && row.approvalStatus == 1) {
            return true;
          }
            return false;

        case '4':
          return (row.approvalStatus != 0 && row.approvalStatus != 5);
        default:
          return true;
      }
    },
    _admissionConfirm() {
      this.$refs['admissionform'].validate((valid) => {
        if (valid) {
          const request = {
            resumeId: this.admissionform.recruitId,
            entryTime: this.admissionform.entryTime,
            hireForm: this.admissionform.hireForm,
            testDate: this.admissionform.testDate,
            turnPositiveTime: this.admissionform.turnPositiveTime
          };

          this.admissionStatus = true;
          employment(request).then((res) => {
            this.admissionStatus = true;
            if (res.code == 0) {
              this.$emit('updateList');
              this.admissiondig = false;
              this.$message.success('录取成功');
            }
          });
        }
      });
    }
  },
  watch: {
    jobArr: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.jobList = newVal;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.admission {
  .buttonDiv {
    text-align: center;
    margin-top: 20px;
  }
  .losetitle {
    margin: 0 20px;
    padding-bottom: 20px;
    // border-bottom: 1px dashed #ccc;
    strong {
      font-size: 18px;
    }
    div {
      margin-top: 10px;
      line-height: 20px;
    }
  }
  .border-line {
    width: 93%;
    margin: 0 20px 20px;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #ccc 0%,
      #ccc 50%,
      transparent 50%
    );
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }
  .loseType,
  .reason {
    margin-left: 20px;
    .el-radio-group {
      margin: 20px 0;
    }
  }
  .reason {
    .el-radio {
      min-width: 16%;
      height: 25px;
    }
  }
}
</style>
